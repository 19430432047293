
@mixin RussoOne {
  font-family: 'Russo One', sans-serif;
}
@mixin Montserrat {
  font-family: 'Montserrat', sans-serif;
}

* {
  padding: 0;
  margin: 0;
}

body {
  @include RussoOne;
  background: #E5E5E5;
}

.flex-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn {
  width: 270px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: #172852;
  text-align: center;
  text-transform: uppercase;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  position: relative;

  transition: .5s;
}
.btn:hover {
  background: #121633;
  color: #fff;
}


// header

.header {
  background-color: #fff;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 20px 0;
  }
  .logo {
    .phone-call {
      display: none;
      background: url('../img/phone-call.svg') center / cover no-repeat;
      width: 30px;
      height: 30px;
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .burger-menu {
      display: none;

    }
  }
  .call {
    p {
      font-size: 16px;
      color: #919191;
      cursor: pointer;
    }
  }

  .navbar-bottom {
    display: flex;
    justify-content: space-around;
    list-style: none;
    flex-grow: 1;
    .navbar-bottom__block {
      display: none;
      width: 100%;
      height: 10px;
      background: #121633;
      padding: 0;
    }
    a {
      font-size: 18px;
      color: #000;
      text-transform: uppercase;
      text-decoration: none;
    }
    a:hover {
      border-bottom: 2px solid #08376D;
    }
  }
}
.header-bottom {
  .block-center {
    padding: 180px 0;
  }
  h1 {
    font-size: 64px;
    line-height: 130%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #08376D;
  }
  h2 {
    font-size: 36px;
    line-height: 130%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    max-width: 505px;
  }
  p {
    @include Montserrat;
    margin-top: 15px;
    max-width: 500px;
    line-height: 142.5%;
  }
}


// direction

.direction {
  margin-bottom: 80px;
  h3 {
    font-size: 36px;
    line-height: 43px;
    color: #000000;
    margin: 80px 0 30px 0;
  }
  .direction-item:nth-child(1) {
    max-width: 456px;
  }
  .direction-item {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    h4 {
      font-size: 20px;
      line-height: 24px;
      color: #000;
      margin: 25px 0 15px 0;
    }
    p {
      @include Montserrat;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 20px;
      flex: 1 1 auto;
    }
    .direction-item__btn {
      flex: 0 1 auto;
    }
  }
}

// license

.license {
  margin-bottom: 80px;
  h3 {
    font-size: 36px;
    line-height: 43px;
    color: #000000;
    margin-bottom: 30px;
  }
  .license-block {
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
    img {
      cursor: pointer;
      width: 263px;
      height: 369px;
      display: block;
      margin: 5px;
    }
  }
}

// geography

.geography {
  h3 {
    font-size: 36px;
    line-height: 43px;

    color: #000000;
  }
  p {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    @include Montserrat;
    max-width: 660px;
    margin: 15px 0 40px 0;
    color: #000000;
  }
  img {
    display: block;
    margin: 0 auto;

    width: 100%;
    max-width: 876px;
    height: auto;
  }
}


// about us

.about-top {
  background: #121633;
  position: relative;
  margin-top: 80px;
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: url('../img/about-left.svg') center/cover no-repeat;
  }
  &__elem {
    display: flex;
    justify-content: flex-end;
    .about-top__block {
      width: 45%;
      margin: 150px 0;
      color: #fff;
      h2 {
        font-size: 30px;
        line-height: 36px;
      }
      p {
        @include Montserrat;
        font-weight: 300;
        font-size: 18px;
        line-height: 140%;
        padding: 30px 0 40px 0;
      }
    }
  }
}

.about-bottom {
  background: #121633;
  position: relative;
  margin-bottom: 80px;
  &__img {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: url('../img/about-right.svg') center/cover no-repeat;
  }
  &__elem {
    display: flex;
    justify-content: flex-start;
    .about-bottom__block {
      width: 49%;
      margin: 100px 0;
      color: #fff;
      h2 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 30px;
      }
      ul {
        list-style: none;
        li {
          @include Montserrat;
          font-weight: 300;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 18px;
          padding-left: 25px;
          transform: translateX(-25px);
          background: url('../img/Check.svg') left no-repeat;
        }
      }
    }
  }
}

// info

.info {
  color: #ffffff;

  background: #121633;
  margin-bottom: 80px;

  position: relative;
  .map-block {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
  }
  .contact {
    display: flex;
    justify-content: flex-end;
  }
  .contact-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    padding: 168px 0;
    h3 {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 32px;
    }
    p {
      @include Montserrat;
      font-size: 18px;
      line-height: 22px;
      font-weight: 300;
      margin-bottom: 18px;
      img {
        margin-right: 24px;
      }
    }
    p:nth-child(4) {
      margin-bottom: 40px;
    }
  }
}


// footer

.footer {
  background-color: #fff;
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 15px 0;
    .logo {
      flex-grow: 1;
    }
  }
  nav {
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;

      list-style: none;
      li {
        margin-right: 50px;
      }
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
  .call {
    padding-left: 50px;
    border-left: 1px solid rgba(31, 32, 65, 0.1);
  }
}


//  Адаптивная
@media screen and (max-width: 1200px) {
  .direction {
    .flex-block {
      justify-content: space-evenly;
      .direction-item {
        max-width: 50%;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 998px) {

  // about

  .about-top {
    margin-bottom: 40px;
    .about-top__img {
      display: none;
    }
    .about-top__elem {
      .about-top__block {
        width: 100%;
        margin: 0;
        padding: 40px 0;
        h2 {
          font-size: 26px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .about-bottom {
    .about-bottom__img {
      display: none;
    }
    .about-bottom__elem {
      .about-bottom__block {
        width: 100%;
        margin: 0;
        padding: 40px 0;
        h2 {
          font-size: 26px;
        }
        ul {
          li {
            font-size: 16px;
          }
        }
      }
    }
  }

  // info

  .info {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
    .map-block {
      position: static;
      height: 300px;
      width: 100%;
    }
    .contact {
      justify-content: flex-start;
      .contact-block {
        padding: 40px 0;
        width: 100%;
        h3 {
          font-size: 26px;
        }
      }
    }
  }


  // footer

  .footer {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .header {
    position: relative;
    header {
      padding: 10px 0;
    }
    .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .phone-call {
        display: block;
      }
      img {
        width: 78px;
        height: 46px;
      }
      .burger-menu {
        display: block;
      }
    }
    .navbar-bottom {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      transform: translateY(100%);
      .navbar-bottom__block {
        display: block;
      }
      li {
        text-align: center;
        padding: 14px 0;
        border-bottom: 1px solid #CDCDCD;
      }
    }
    .call {
      display: none;
    }
  }
  .header-bottom {
    .block-center {
      margin: 0;
      padding: 50px 0;
      h1 {
        font-size: 24px;
      }
      h2 {
        font-size: 20px;
      }
      p {
        max-width: 200px;
        font-size: 12px;
        margin-top: 0;
      }
    }
  }
  .direction {
    margin-bottom: 0;
    h3 {
      display: none;
    }
    .flex-block {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 50px;
      .direction-item {
        max-width: 100%;
        margin-bottom: 50px;
        p {
          font-size: 15px;
          line-height: 15px;
        }
      }
    }
  }
  .btn {
    width: 100%;
  }
  .btn-mobile {
    background: #172852;
    color: #fff;
  }

  .license {
    margin-bottom: 50px;
    h3 {
      font-size: 26px;
      line-height: 24px;
    }
  }
  .geography {
    h3 {
      font-size: 26px;
      line-height: 24px;
    }
    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 15px;
    }
  }
  .about-top {
    margin-top: 50px;
  }
  .about-bottom {
    margin-bottom: 50px;
    &__elem {
      .about-bottom__block {
        ul {
          li {
            transform: translateX(0);
          }
        }
      }
    }
  }
}

section{
  padding: 0 15px;
}
@media (min-width: 575.98px){
  section {
    padding: 0 calc(50% - 270px);
  }
}

@media (min-width: 767.98px){
  section {
    padding: 0 calc(50% - 360px);
  }
}

@media (min-width: 991.98px) {
  section {
    padding: 0 calc(50% - 480px);
  }
}

@media (min-width: 1199.98px){
  section {
    padding: 0 calc(50% - 590px);
  }
}




// burger-menu



.burger-menu__btn {
  width: 40px;
  cursor: pointer;
  span {
    display: block;
    width: 100%;
    height: 5px;
    background: #121633;
    transition: all .3s;
    position: relative;
  }
  span + span {
    margin-top: 7px;
  }
}

.active span:nth-child(1) {
  animation: ease .7s top forwards;
}

.not-active span:nth-child(1) {
  animation: ease .7s top-2 forwards;
}

.active span:nth-child(2) {
  animation: ease .7s scaled forwards;
}

.not-active span:nth-child(2) {
  animation: ease .7s scaled-2 forwards;
}

.active span:nth-child(3) {
  animation: ease .7s bottom forwards;
}

.not-active span:nth-child(3) {
  animation: ease .7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 12px;
    transform: rotate(0);
  }
  100% {
    top: 12px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 12px;
    transform: rotate(45deg);
  }
  50% {
    top: 12px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 12px;
    transform: rotate(0);
  }
  100% {
    bottom: 12px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 12px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 12px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

// scroll

#button-up{
  display: none;
  position: fixed;
  right: 4%;
  bottom: 10%;
  color: #fff;
  background-color: #121633;
  text-align: center;
  font-size: 30px;
  padding: 15px;
  transition: .3s;

  width: 48px;
  height: 48px;
  border-radius: 50%;

  z-index: 200;
}

#button-up:hover{
  cursor: pointer;
  background-color: rgba(18,22,51,0.3);
  transition: .3s;
}

// modalCall

#modal-call {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  max-width: 420px;
  margin: 0 auto;
  z-index: 150;
  .modal-call {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #E4E4E4;
    border-radius: 2px;
    padding: 75px;
    box-sizing: border-box;
    ::-webkit-input-placeholder {color: #DADADA; font-size: 14px; font-weight: 300;}
    ::-moz-placeholder          {color: #DADADA; font-size: 14px; font-weight: 300;}
    :-moz-placeholder           {color: #DADADA; font-size: 14px; font-weight: 300;}
    :-ms-input-placeholder      {color: #DADADA; font-size: 14px; font-weight: 300;}
    form {
      width: 100%;
    }
    h2 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 25px;
    }
    .input {
      width: 100%;
      box-sizing: border-box;
      height: 40px;
      outline: none;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 20px;
      padding-left: 30px;
      border: none;
      border-radius: 2px;
    }
    input:valid:not(:placeholder-shown) {
      border-color: green;
    }

    input:invalid:not(:placeholder-shown) {
      border-color: red;
    }
    .btn-modal {
      width: 100%;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      background: #172852;
      border: 1px solid #E4E4E4;
      box-sizing: border-box;
      border-radius: 2px;
      cursor: pointer;
    }
  }
}

#modal-call-two {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  max-width: 420px;
  margin: 0 auto;
  z-index: 149;
  .modal-call {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #e5e5e5;
    border-radius: 2px;
    box-sizing: border-box;
    width: 420px;
    height: 359px;
    padding: 50px;

    .modal-call-title {
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      text-align: center;
      color: #000000;
      margin-bottom: 30px;
      @include RussoOne;
    }
    p {
      @include Montserrat;
      text-align: center;
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }

}

#modal-filter {
  position: fixed;
  background: rgba(0,0,0, .6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: none;
}


// увеличение изображения

.image {
  margin:30px;
  cursor:zoom-in;
}

.popup {
  position: absolute;
  height:100%;
  width:100%;
  top:0;
  left:0;
  display:none;
  text-align:center;
}

.popup_bg {
  background:rgba(0,0,0,0.3);
  position:fixed;
  z-index:1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;
}


.popup_img {
  position: fixed;
  z-index:2;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  max-height: 90%;
  background: #fff;
}

.popup_img {
  pointer-events: none;
}




